<template>
	<main class="page-container" id="sme-noticeinfo-view">
		<div class="page-component">
			<div class="page-header blind">
				<div class="page-component">
					<div class="page-header-title">
						<h2 class="title">소상공인 정보놀이터</h2>
					</div>
					<div class="page-header-hashtags">
						<p class="text">{{ tags }}</p>
					</div>
				</div>
			</div>
			<div class="page-body">
				<section class="post-view-wrapper">
					<header class="post-view-header">
						<div class="header-category">
							<div class="badge">
								<span class="badge-text">{{ plygrndCtgr2 }}</span>
							</div>
						</div>
						<h3 class="header-title">{{ plygrndTit }}</h3>
						<p class="header-tags">{{ tags }}</p>
					</header>
					<div class="post-view-body">
						<div class="gachi-view" v-html="plygrndCn"></div>
					</div>
				</section>

				<div class="livestudio-intro">
					<div class="studio-data">
						<div class="studio-data-description">
							<ul class="description-list">
								<!-- <li v-for="(info, idx) in studioInfos" class="description-item" :key="`info-${idx}`">
                      <span v-if="info.isHtml" class="text" v-html="info.msg"></span>
                      <span v-else class="text">{{info.msg}}</span>
                    </li> -->
							</ul>
						</div>
					</div>
				</div>
				<!-- BEGIN: ROOM -->
				<room :studio-nm="studioNm" :items="roomItems" />
				<!-- END: ROOM -->

				<div class="page-buttons" data-align="center">
					<button v-if="atchFilePtUrl" class="button-default is-large is-rounded is-secondary" :disabled="downing" @click="clickDownload">
						<span class="text">{{ downing ? '다운로드 중...' : '첨부파일' }}<br />[{{ atchFileNm }}]</span>
					</button>
				</div>
				<div v-if="isMobile" class="page-buttons" data-align="center" style="flex-direction: row; width: 100%; margin: auto">
					<a href="javascript:">
						<next-prev-button route-name="Playground2View" param-key="plygrndId" queryKey="ctgrCode" :query="$route.query.ctgrCode" :pre-id="preId" :next-id="nextId"></next-prev-button>
					</a>
				</div>
				<div v-else class="page-buttons" data-align="center" style="flex-direction: row; width: 900px; margin: auto">
					<a href="javascript:">
						<next-prev-button route-name="Playground2View" param-key="plygrndId" queryKey="ctgrCode" :query="$route.query.ctgrCode" :pre-id="preId" :next-id="nextId"></next-prev-button>
					</a>
				</div>
				<a href="javascript:">
					<div v-if="isMobile" class="list-btn-cont" style="width: 100px; position: absolute; left: calc(50% - 50px); top: calc(100% - 80px)">
						<button class="button-default is-large is-rounded is-secondary" @click="goList">
							<span class="text">목록</span>
						</button>
					</div>
					<div v-else class="list-btn-cont" style="width: 150px; position: absolute; left: calc(50% - 75px); top: calc(100% - 56px)">
						<button class="button-default is-large is-rounded is-secondary" @click="goList">
							<span class="text">목록</span>
						</button>
					</div>
				</a>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_DOWN_ATTACH_PLAYGROUND2, ACT_DOWNLOAD_PLAYGROUND2, ACT_GET_PLAYGROUND, ACT_GET_PLAYGROUND2, ACT_UPDATE_COMMON_INQ, ACT_GET_STUDIO_INFO_LIST } from '../../../store/_act_consts';
import { collectionCheck, getItem, getItems, lengthCheck, queryToValue, viewTags, tryResCallback } from '../../../assets/js/utils';
import { viewCount } from '../../../assets/js/func';
import { msgAlert } from '../../../assets/js/modules/validation';
import NextPrevButton from '../../../components/common/NextPrevButton';
import Room from '../../../components/studio/Room';
import { mapGetters } from 'vuex';

export default {
	name: 'Playground2View',
	components: { NextPrevButton, Room },
	watch: {
		$route(to) {
			this.plygrndId = queryToValue(to.params.plygrndId, true, 0);
			this.getPlayground();
		},
	},
	data: () => ({
		studio: null,
		studioItems: [],
		plygrndId: 0,
		plygrndCtgr2: '',
		plygrndTit: '',
		tags: '',
		plygrndCn: '',
		atchFileNm: '',
		atchFilePtUrl: '',
		downing: false,
		preId: 0,
		nextId: 0,
		studoId: 0,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		studioNm() {
			if (this.studio) return this.studio.studoNm;
			return '';
		},
		roomItems() {
			if (this.studio && collectionCheck(this.studio.studoRoomInfos, 'roomId')) {
				return this.studio.studoRoomInfos;
			}
			return [];
		},
		studioInfos() {
			const infos = [];
			if (this.studio) {
				// time
				const beginHour = parseInt(this.studio.operBgngHhmm.substr(0, 2));
				const beginMinute = this.studio.operBgngHhmm.substr(2, 2);
				const endHour = parseInt(this.studio.operEndHhmm.substr(0, 2));
				const endMinute = this.studio.operBgngHhmm.substr(2, 2);
				let timeMsg = `${this.getMeridiem(beginHour)} ${beginHour}:${beginMinute} ~ ${this.getMeridiem(endHour)} ${endHour}:${endMinute}`;
				const addedOpens = [];
				if (this.studio.wekendOperYn !== 'Y') addedOpens.push('주말');
				if (this.studio.holdyOperYn !== 'Y') addedOpens.push('공휴일');
				if (addedOpens.length > 0) timeMsg += ` <em class="text-secondary">${addedOpens.join('·')} 휴무</em>`;
				infos.push({ icon: 'time', msg: timeMsg, isHtml: true });
				// park
				if (this.studio.parkDesc) infos.push({ icon: 'parking', msg: this.studio.parkDesc });
				// location
				const locations = [];
				if (this.studio.bscAddr) locations.push(this.studio.bscAddr);
				if (this.studio.dtlAddr) locations.push(this.studio.dtlAddr);
				if (locations.length > 0) infos.push({ icon: 'location', msg: locations.join(' ') });
				// phone
				if (this.studio.studoCnctel) infos.push({ icon: 'phone', msg: this.studio.studoCnctel });
			}
			return infos;
		},
	},
	created() {
		this.plygrndId = this.$route.params.plygrndId;
		viewCount(this.plygrndId, 'plygrnd2');
		this.getPlayground();
		this.getStudioInfos();
	},
	methods: {
		goList() {
			this.$router.push({ name: 'Playground2', query: { ctgrCode: this.$route.query.ctgrCode } });
		},
		getPlayground() {
			this.$store
				.dispatch(`studio/${ACT_GET_PLAYGROUND2}`, {
					plygrndId: this.plygrndId,
					plygrndCtgrDcd: this.$route.query.ctgrCode,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						const item = getItem(res);
						this.studoId = item.studoId;
						this.plygrndId = item.plygrndId;
						this.plygrndCtgr2 = item.plygrndCtgr2;
						this.plygrndTit = item.plygrndTit;
						this.tags = viewTags(item, 'plygrndTag');
						this.plygrndCn = item.plygrndCn;
						this.atchFileNm = item.atchFileNm;
						this.atchFilePtUrl = item.atchFilePtUrl;
						this.preId = item.preId;
						this.nextId = item.nextId;
					} else {
						this.plygrndId = 0;
					}
				})
				.catch((e) => {
					console.error(e);
					this.plygrndId = 0;
				});
		},
		getStudioInfos() {
			this.$store
				.dispatch(`studio/${ACT_GET_STUDIO_INFO_LIST}`)
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getStudioInfos, this.setStudioInfos);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setStudioInfos(res) {
			if (lengthCheck(res)) {
				this.studioItems = getItems(res);
				for (i = 0; i < this.studioItems.length; i++) {
					if (this.studoId === this.studioItems[i].studoId) {
						this.studio = this.studioItems[i];
					}
				}
			}
		},
		getMeridiem(hour) {
			if (hour < 12) {
				return '오전';
			}
			return '오후';
		},

		clickDownload() {
			// window.open(`${this.atchFilePtUrl}`);
			this.downing = true;
			this.$store
				.dispatch(`community/${ACT_DOWN_ATTACH_PLAYGROUND2}`, {
					plygrndId: this.plygrndId,
					fileName: this.atchFileNm,
				})
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', this.atchFileNm);
					document.body.appendChild(link);
					link.click();
					this.downing = false;
				})
				.catch((err) => {
					console.log(err);
					this.downing = false;
				});
		},
	},
};
</script>
